import { render, staticRenderFns } from "./choseLiveList.vue?vue&type=template&id=105c8010&scoped=true&"
import script from "./choseLiveList.vue?vue&type=script&lang=js&"
export * from "./choseLiveList.vue?vue&type=script&lang=js&"
import style0 from "./choseLiveList.vue?vue&type=style&index=0&id=105c8010&prod&lang=less&scoped=true&"
import style1 from "./choseLiveList.vue?vue&type=style&index=1&id=105c8010&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105c8010",
  null
  
)

export default component.exports