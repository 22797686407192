<template>
  <div class="editBox">
    <pageTitle :pageTitle="pageTitle">
      <div class="title_text" v-if="!isOldSubject && $route.params.id">
        该专题是旧版系统创建，不能设置统一的观看限制。
      </div>
    </pageTitle>
    <vh-form :model="formData" ref="ruleForm" :rules="rules" v-loading="loading" label-width="92px">
      <vh-form-item label="专题标题" prop="title">
        <vh-input
          v-model="formData.title"
          :maxlength="100"
          autocomplete="off"
          placeholder="请输入中文标题"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="" prop="en_title" v-if="$route.query.type == 2">
        <vh-input
          v-model="formData.en_title"
          :maxlength="100"
          autocomplete="off"
          placeholder="请输入英文标题"
          show-word-limit
        ></vh-input>
      </vh-form-item>
      <vh-form-item label="中文封面">
        <upload
          class="upload__avatar"
          ref="subject_uploader"
          v-model="formData.imageUrl"
          :heightImg="120"
          :domain_url="domain_url"
          :saveData="saveImageData"
          :on-success="handleUploadSuccess"
          :on-change="handleUploadChange"
          @delete="deleteImg"
          :auto-upload="false"
        >
          <div slot="tip">
            <p>建议尺寸：1280*720px，小于4M</p>
            <p>支持jpg、gif、png、bmp</p>
          </div>
        </upload>
       
      </vh-form-item>
      <vh-form-item v-if="$route.query.type == 2" label="英文封面">
        <upload
          class="upload__avatar"
          ref="en_subject_uploader"
          v-model="formData.en_imageUrl"
          :heightImg="120"
          :domain_url="en_domain_url"
          :saveData="saveImageData"
          :on-success="handleUploadSuccessEn"
          :on-change="handleUploadChangeEn"
          @delete="deleteImgEn"
          :auto-upload="false"
        >
          <div slot="tip">
            <p>建议尺寸：1280*720px，小于4M</p>
            <p>支持jpg、gif、png、bmp</p>
          </div>
        </upload>
       
      </vh-form-item>
      <vh-form-item label="专题简介" required>
        <v-editor
          save-type="special"
          :isReturn="true"
          :maxWord="5000"
          @returnChange="sendData"
          ref="unitImgTxtEditor"
          placeholder="请输入中文简介"
          v-model="formData.content"
        ></v-editor>
      </vh-form-item>
      <vh-form-item label="" required v-if="$route.query.type == 2">
        <v-editor
          save-type="special"
          :isReturn="true"
          :maxWord="5000"
          @returnChange="sendData"
          ref="unitImgTxtEditor"
          placeholder="请输入英文简介"
          v-model="formData.en_content"
        ></v-editor>
      </vh-form-item>
      <p class="switch__box">
        <vh-switch
          v-model="formData.reservation"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          inactive-text="预约人数"
          :active-text="reservationDesc"
        ></vh-switch>
      </p>
      <p class="switch__box">
        <vh-switch
          v-model="formData.hot"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          inactive-text="专题热度"
          :active-text="hotDesc"
        ></vh-switch>
      </p>
      <p class="switch__box">
        <vh-switch
          v-model="formData.home"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          inactive-text="关联主页"
          :active-text="homeDesc"
        ></vh-switch>
      </p>
      <vh-form-item label="专题目录" required v-if="$route.query.type == 1">
        <vh-button type="info" size="small" plain round @click="showActiveSelect = true">
          添加
        </vh-button>
        <span class="form_tip">
          {{ $route.params.id ? '标红活动在其他专题重复使用' : '专题保存成功后，可设置观看限制' }}
        </span>
        <div class="vh-sort-tables" v-show="formData.selectedActives.length">
          <div class="vh-sort-tables__theader">
            <div class="vh-sort-tables__theader-id">序号</div>
            <div class="vh-sort-tables__theader-title">直播标题</div>
            <div class="vh-sort-tables__theader-time">开播时间</div>
            <div class="vh-sort-tables__theader-status">直播状态</div>
            <div class="vh-sort-tables__theader-hots">热度</div>
            <div class="vh-sort-tables__theader-editor">操作</div>
          </div>
          <div class="vh-sort-tables__tbody">
            <draggable
              :list="formData.selectedActives"
              @update="updateDrag"
              @dragstart="dragStart"
              @dragend="dragEnd"
              handle=".handle"
            >
              <div
                class="vh-sort-tables__tbody-tr"
                :class="$route.params.id && item.is_coincide == 1 ? 'tr_active' : ''"
                v-for="(item, index) in formData.selectedActives"
                :key="item.webinar_id"
              >
                <div class="vh-sort-tables__tbody-id">
                  {{ index + 1 }}
                </div>
                <div
                  class="vh-sort-tables__tbody-title"
                  @click="goWebinarSpecial(item.webinar_id, item.webinar_state, item.player)"
                >
                  <vh-tooltip effect="dark" placement="top-start" :content="item.subject">
                    <span>{{ item.subject }}</span>
                  </vh-tooltip>
                </div>
                <div
                  class="vh-sort-tables__tbody-time"
                  @click="goWebinarSpecial(item.webinar_id, item.webinar_state, item.player)"
                >
                  {{ item.start_time }}
                </div>
                <div class="vh-sort-tables__tbody-status">
                  <template>
                    <span :class="'statusTag tag_' + item.webinar_state"></span>
                    {{ item.webinar_state | actionText }}
                  </template>
                </div>
                <div class="vh-sort-tables__tbody-hots">
                  {{ item.pv | formatNum }}
                </div>
                <div class="vh-sort-tables__tbody-editor">
                  <vh-tooltip
                    class="item"
                    effect="dark"
                    content="删除"
                    placement="top"
                    v-tooltipMove
                  >
                    <!-- <i class="iconfont-v3 saasicon-trash"></i> -->
                    <vh-button
                      type="text"
                      icon="iconfont-v3 saasicon-trash"
                      @click.prevent.stop="deleteSpecial(item.webinar_id)"
                    ></vh-button>
                  </vh-tooltip>
                  <vh-tooltip
                    class="item"
                    effect="dark"
                    content="移动"
                    placement="top"
                    v-tooltipMove
                  >
                    <vh-button type="text" icon="iconfont-v3 saasicon_move handle"></vh-button>
                  </vh-tooltip>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </vh-form-item>
      <vh-form-item label="双语活动组" required v-if="$route.query.type == 2" :key="updataview">
        <draggable v-model="language_group" handle=".handle">
          <div
            class="languages_group"
            v-for="(item, index) in language_group"
            :key="item.language_group_id"
          >
            <div class="table_box">
              <vh-table
                :data="item.webinar_list"
                :row-class-name="tableRowClassName"
                :span-method="arraySpanMethod"
              >
                <vh-table-column label="直播标题" width="150">
                  <template slot-scope="scope">
                    <p style="text-align: center" v-if="!scope.row.id">
                      <vh-button
                        type="text"
                        size="small"
                        v-if="scope.$index == 0 && !scope.row.id"
                        @click="addActivity(index, scope.$index)"
                      >
                        添加中文活动
                      </vh-button>
                      <vh-button
                        type="text"
                        size="small"
                        v-else-if="scope.$index == 1 && !scope.row.id"
                        @click="addActivity(index, scope.$index)"
                      >
                        添加英文活动
                      </vh-button>
                    </p>
                    <vh-tooltip
                      v-else
                      class="item"
                      effect="dark"
                      :content="scope.row.subject"
                      placement="top"
                    >
                      <span
                        class="link ellsips"
                        @click="
                          goWebinarSpecial(scope.row.id, scope.row.webinar_state, scope.row.player)
                        "
                      >
                        {{ scope.row.subject }}
                      </span>
                    </vh-tooltip>
                  </template>
                </vh-table-column>
                <vh-table-column label="直播时间" width="150" prop="start_time"></vh-table-column>
                <vh-table-column label="直播状态" width="80">
                  <template slot-scope="scope">
                    <span :class="'statusTag tag_' + scope.row.webinar_state"></span>
                    {{ scope.row.webinar_state | actionText }}
                  </template>
                </vh-table-column>
                <vh-table-column label="热度" width="80">
                  <template slot-scope="scope">{{ scope.row.pv | formatNum }}</template>
                </vh-table-column>
                <vh-table-column label="操作" width="60">
                  <template slot-scope="scope">
                    <vh-button type="text" @click="delWebinar(index, scope.$index)">
                      <vh-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="top"
                        v-tooltipMove
                      >
                        <i class="iconfont-v3 saasicon-trash"></i>
                      </vh-tooltip>
                    </vh-button>
                  </template>
                </vh-table-column>
              </vh-table>
              <div class="group_handle">
                <vh-button type="text">
                  <vh-tooltip
                    class="item"
                    effect="dark"
                    content="删除"
                    placement="top"
                    v-tooltipMove
                  >
                    <i class="iconfont-v3 saasicon-trash" @click="delGroup(index)"></i>
                  </vh-tooltip>
                </vh-button>
                <vh-button type="text">
                  <vh-tooltip
                    class="item"
                    effect="dark"
                    content="移动"
                    placement="top"
                    v-tooltipMove
                  >
                    <i class="iconfont-v3 saasicon_move handle"></i>
                  </vh-tooltip>
                </vh-button>
              </div>
            </div>
            <p class="error_group">{{ checkGroup(item) }}</p>
          </div>
        </draggable>
        <p class="add_btn">
          <vh-button
            type="text"
            size="small"
            @click="addLanguageGroup"
            icon="vh-icon-circle-plus-outline"
            :disabled="language_group.length >= 50"
          >
            添加双语活动组
          </vh-button>
          &nbsp; &nbsp; 提示：中文活动的观看语种建议选择简体中文；英文活动的观看语种建议选择英文
        </p>
      </vh-form-item>
      <vh-form-item label="">
        <vh-button
          type="primary"
          class="length152"
          :disabled="!formData.title || ($route.query.type == 2 && !formData.en_title)"
          @click="submitForm('ruleForm')"
          v-preventReClick
          round
        >
          保存
        </vh-button>
        <vh-button
          type="info"
          plain
          class="length152"
          @click="resetForm('ruleForm')"
          v-preventReClick
          round
        >
          取消
        </vh-button>
      </vh-form-item>
    </vh-form>
    <chose-actives
      v-if="showActiveSelect"
      :checkedList="selectedActives"
      :checkedTotal="formData.total"
      :checkAuth="formData.viewer"
      :params="searchParams"
      @cacelSelect="showActiveSelect = false"
      @selectedEvent="doSelectedActives"
      :multiple="multiple"
    ></chose-actives>

    <!-- 裁剪组件 -->
    <cropper
      ref="subjectCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
    ></cropper>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';
  import PageTitle from '@/components/PageTitle';
  import upload from '@/components/Upload/main';
  import VEditor from '@/components/Tinymce';
  import ChoseActives from './components/choseLiveList';
  import {
    sessionOrLocal,
    buildOssUploadProcessData,
    parseQueryString,
    buildUrlByMode
  } from '@/utils/utils';
  import cropper from '@/components/Cropper/index';

  export default {
    components: {
      PageTitle,
      VEditor,
      upload,
      ChoseActives,
      draggable,
      cropper
    },
    computed: {
      selectedActives() {
        if (this.$route.query.type == 1) {
          return this.formData.selectedActives;
        } else {
          const list = [];
          this.language_group.forEach(item => {
            item.webinar_list.forEach(el => {
              el.id && list.push(el);
            });
          });
          return list;
        }
      },
      pageTitle() {
        let action = this.$route.params.id ? '编辑' : '创建';
        let subjectType =
          this.$route.query.type == 1 ? '中文' : this.$route.query.type == 2 ? '双语' : '';
        return `${action}${subjectType}专题`;
      },
      domain_url() {
        if (!this.formData.imageUrl) return '';
        return buildUrlByMode(this.formData.imageUrl, this.cropperImage.imageCropMode);
      },
      en_domain_url() {
        if (!this.formData.en_imageUrl) return '';
        return buildUrlByMode(this.formData.en_imageUrl, this.en_cropperImage.imageCropMode);
      },
      reservationDesc() {
        return this.formData.reservation
          ? '已开启，专题页显示预约人数'
          : '开启后，专题页显示预约人数';
      },
      hotDesc() {
        return this.formData.hot ? '已开启，专题页显示热度' : '开启后，专题页显示热度';
      },
      homeDesc() {
        return this.formData.home
          ? '已开启，该专题在个人主页中显示'
          : '开启后，该专题在个人主页中显示';
      },
      title() {
        return this.$route.query.title;
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'webinars/subject-imgs',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    data() {
      return {
        showActiveSelect: false,
        formData: {
          selectedActives: [],
          title: '',
          en_title: '',
          reservation: true,
          imageUrl: '',
          en_imageUrl: '',
          content: '',
          en_content: '',
          hot: true,
          home: true,
          total: 0,
          viewer: 0
        },
        language_group: [{ language_group_id: '', webinar_list: [{}, {}] }],
        subject_id: '',
        isOldSubject: true,
        userId: JSON.parse(sessionOrLocal.get('userId')),
        loading: false,
        webinarIds: [],
        rules: {
          title: [{ required: true, message: '请输入专题标题', trigger: 'blur' }],
          en_title: [{ required: true, message: '请输入专题标题', trigger: 'blur' }]
        },
        cropperImage: {
          imageCropMode: 1
          
        },
        en_cropperImage: {
          imageCropMode: 1
          
        },
        isChange: false,
        languagesActivity: {},
        multiple: true,
        curUpload: '', //当前上传的标识
        searchParams: {
          exclude_subject_type: this.$route.query.type == 2 ? 1 : 2,
          subject_id: this.$route.params.id
        }, //活动选择接口参数
        errorWebinar: [],
        errorGroup: [],
        updataview: 1,
        upload_process_data: '', //上传组件的附加数据
        currentMode: 1 //当前的正在使用的裁切模式
      };
    },
    created() {
      window.scrollTo(0, 0);
    },
    mounted() {
      if (this.$route.params.id) {
        this.initInfo();
      }
    },
    watch: {
      formData: {
        deep: true,
        handler() {
          this.isChange = true;
        }
      },
      title(newVal, oldVal) {
        if (newVal != oldVal) {
          this.formData = {
            selectedActives: [],
            title: '',
            reservation: true,
            imageUrl: '',
            content: '',
            hot: true,
            home: true
          };
          this.$refs.unitImgTxtEditor.$refs.editor.resetContent();
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      // 离开页面前判断信息是否修改
      if (!this.isChange) {
        next();
        return false;
      }
      this.$vhConfirm(`取消将不保存此页面的内容？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        lockScroll: false,
        roundButton: true,

        cancelButtonClass: 'zdy-confirm-cancel'
      })
        .then(() => {
          next();
        })
        .catch(() => {});
    },
    methods: {
      // 获取专题 - 详情
      initInfo() {
        this.$fetch('subjectInfo', {
          subject_id: this.$route.params.id
        }).then(res => {
          if (res.code == 200) {
            this.isOldSubject = Boolean(res.data.webinar_subject.is_new_version);
            this.formData.selectedActives = Array.from(res.data.webinar_subject.webinar_list) || [];
            this.subject_id = res.data.webinar_subject.id;
            this.formData.title = res.data.webinar_subject.title;
            this.formData.en_title = res.data.webinar_subject.en_title;
            if (res.data.webinar_subject.cover) {
              this.handlerImageInfo(res.data.webinar_subject.cover);
            }
            if (res.data.webinar_subject.en_cover) {
              this.handlerImageInfo(res.data.webinar_subject.en_cover, 'en');
            }
            // res.data.webinar_subject.cover && (this.formData.imageUrl = res.data.webinar_subject.cover)
            // this.formData.domain_url = res.data.webinar_subject.cover;
            this.formData.content = res.data.webinar_subject.intro;
            this.formData.en_content = res.data.webinar_subject.en_intro;
            this.formData.total = res.data.webinar_subject.webinar_num;
            // 配置项
            this.formData.home = res.data.webinar_subject.is_open ? false : true; // 是否显示个人主页
            this.formData.hot = Boolean(res.data.webinar_subject.hide_pv); // 是否显示 人气
            this.formData.reservation = Boolean(res.data.webinar_subject.hide_appointment); // 是否显示预约人数
            this.formData.viewer = Number(res.data.webinar_subject.subject_verify);
            this.searchParams.exclude_subject_type =
              this.$route.query.type == 2 || this.formData.viewer ? 1 : 2;
            this.language_group = res.data.webinar_subject.language_group_list.map(group => {
              group.webinar_list = group.webinar_list.sort((a, b) => {
                return a.language_type - b.language_type;
              });
              return group;
            });
            // 重置修改状态
            setTimeout(() => {
              this.isChange = false;
            }, 300);
          }
        });
      },
      /**
       * 处理图片数据
       *
       * @param   {[type]}  url   [url description]
       * @param   {[type]}  type  [type description]
       *
       * @return  {[type]}        [return description]
       */
      handlerImageInfo(url, type) {
        if (type == 'en') {
          this.formData.en_imageUrl = url;
          let obj = parseQueryString(url);
          if (obj) {
            this.en_cropperImage.imageCropMode = obj.mode;
          }
        } else {
          this.formData.imageUrl = url;
          let obj = parseQueryString(url);
          if (obj) {
            this.cropperImage.imageCropMode = obj.mode;
          }
        }
      },
      sendData(content) {
        this.formData.content = content;
      },
      cropComplete(cropperData, url, mode, indexId) {
        this.currentMode = mode;

        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
            // blurryDegree: blurryDegree,
            // lightDegree: lightDegree
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          //图片显示模式分开设置
          if (indexId == 'poster-en') {
            this.en_cropperImage.imageCropMode = mode;
            this.$refs.en_subject_uploader.upload();
          } else {
            this.cropperImage.imageCropMode = mode;
            this.$refs.subject_uploader.upload();
          }
        });
      },
      resetChooseFile(indexId) {
        if (indexId == 'poster-en') {
          this.$refs.en_subject_uploader.resetChooseFile();
        } else {
          this.$refs.subject_uploader.resetChooseFile();
        }
      },
      handleUploadSuccess(res, file) {
        if (res.data) {
          this.formData.imageUrl = res.data.domain_url;
        }
      },
      handleUploadSuccessEn(res, file) {
        if (res.data) {
          this.formData.en_imageUrl = res.data.domain_url;
        }
      },
      /**
       * 图片本地裁剪预览
       * @param {*} file
       * @param {*} fileList
       */
      handleUploadChangeCommon(file, fileList, indexId) {
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, 1, 4, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.subjectCropper.showModel(e.target.result, indexId);
            };
          });
        }
      },
      // 中文封面
      handleUploadChange(file, fileList) {
        this.currentMode = this.cropperImage.imageCropMode;
        this.handleUploadChangeCommon(file, fileList, 'poster-cn');
      },
      // 英文封面
      handleUploadChangeEn(file, fileList) {
        this.currentMode = this.en_cropperImage.imageCropMode;
        this.handleUploadChangeCommon(file, fileList, 'poster-en');
      },
      // 保存
      submitForm(formName) {
        window.cd = this.formData;
        if (!this.formData.content) {
          this.$vhMessage({
            message: `请输入专题简介`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        if (this.$route.query.type == 2 && !this.formData.en_content) {
          this.$vhMessage({
            message: `请输入英文专题简介`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        if (!this.formData.selectedActives.length && this.$route.query.type != 2) {
          this.$vhMessage({
            message: `请选择专题目录`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        if (!this.checkGroupListInfo()) {
          this.$vhMessage({
            message: `请设置双语活动`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        this.$refs[formName].validate(valid => {
          if (valid) {
            let data = {
              subject: this.formData.title,
              introduction: this.formData.content,
              img_url: this.$parseURL(this.domain_url).path,
              is_private: this.formData.home ? 0 : 1,
              hide_appointment: Number(this.formData.reservation),
              hide_pv: Number(this.formData.hot),
              subject_verify: this.formData.viewer
            };
            let webinar_ids;
            if (this.$route.query.type != 2) {
              data.type = 1;
              webinar_ids = this.formData.selectedActives.map(item => {
                return item.webinar_id || item.id;
              });
              if (webinar_ids.length) {
                data.webinar_ids = webinar_ids.join(',');
              }
            } else {
              data.type = 3;
              data.en_subject = this.formData.en_title;
              data.en_introduction = this.formData.en_content;
              data.en_img_url = this.$parseURL(this.en_domain_url).path;
              data.language_group = JSON.stringify(
                this.language_group.map(item => {
                  const el = {
                    cn_webinar_id: item.webinar_list[0].webinar_id || item.webinar_list[0].id,
                    en_webinar_id: item.webinar_list[1].webinar_id || item.webinar_list[1].id
                  };
                  item.language_group_id && (el.language_group_id = item.language_group_id);
                  return el;
                })
              );
            }

            this.loading = true;
            let url = this.$route.params.id ? 'subjectEdit' : 'subjectCreate';

            if (url == 'subjectEdit') {
              data.id = this.subject_id;
            }
            this.$fetch(url, data)
              .then(res => {
                if (!this.$route.params.id) {
                  let refer = this.$route.query.refer || 2;
                  this.$vhall_paas_port({
                    k: 100489,
                    data: {
                      business_uid: this.userId,
                      user_id: '',
                      webinar_id: '',
                      subject_id: '',
                      refer: refer,
                      s: '',
                      report_extra: {},
                      ref_url: '',
                      req_url: ''
                    }
                  });
                }

                if (res.code == 200) {
                  webinar_ids && this.setReportData(webinar_ids.length);
                  this.subject_id = res.data.subject_id;
                  this.$vhMessage({
                    message: this.$route.params.id ? '编辑成功' : `创建成功`,
                    showClose: true,
                    // duration: 0,
                    type: 'success',
                    customClass: 'zdy-info-box'
                  });
                  // 保存或创建成功重置更改状态
                  this.isChange = false;
                  let subject_id = this.subject_id || this.$route.params.id;
                  if (!this.isOldSubject && subject_id) {
                    this.$router.push({ path: `/special/list` });
                  } else {
                    this.$router.push({ path: `/special/details/${subject_id}` });
                  }
                  console.log(res);
                }
              })
              .catch(error => {
                if (error.code == 512002) {
                  this.updataview++;
                  this.errorWebinar = error?.data?.not_exists_ids.split(',');
                  this.$vhMessage({
                    message: '数据中存在已删除活动，请检查',
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                  return;
                }
                if (error.code == 512205) {
                  this.updataview++;
                  this.errorGroup = error?.data?.not_exists_ids.split(',');
                  this.$vhMessage({
                    message: '数据中存在已删除活动组，请检查',
                    showClose: true,
                    // duration: 0,
                    type: 'error',
                    customClass: 'zdy-info-box'
                  });
                  return;
                }
                this.$vhMessage({
                  message: this.$route.params.id ? '编辑失败' : `创建失败，${error.msg}`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              })
              .finally(() => {
                this.loading = false;
              });
            console.log(data);
          } else {
            this.$vhMessage({
              message: `请完善必填字段`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
            document.documentElement.scrollTop = 0;
            return false;
          }
        });
      },
      setReportData(len) {
        if (this.formData.imageUrl) {
          this.$vhall_paas_port({
            k: 100498,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              subject_id: this.$route.params.id || '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.$vhall_paas_port({
          k: this.formData.reservation ? 100499 : 100500,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            subject_id: this.$route.params.id || '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$vhall_paas_port({
          k: this.formData.hot ? 100501 : 100502,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            subject_id: this.$route.params.id || '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$vhall_paas_port({
          k: this.formData.home ? 100503 : 100504,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            subject_id: this.$route.params.id || '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$vhall_paas_port({
          k: 100505,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            subject_id: this.$route.params.id || '',
            refer: '',
            s: '',
            report_extra: { vip: len },
            ref_url: '',
            req_url: ''
          }
        });
      },
      // 判断是否填写数据
      isContent() {
        if (
          this.formData.title ||
          this.formData.content ||
          this.formData.imageUrl ||
          !this.formData.home ||
          !this.formData.reservation ||
          !this.formData.hot ||
          this.formData.selectedActives.length > 0
        ) {
          this.$vhConfirm(`取消将不保存此页面的内容？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            roundButton: true,

            lockScroll: false,
            cancelButtonClass: 'zdy-confirm-cancel'
          })
            .then(() => {
              this.$router.push({ path: '/special/list' });
            })
            .catch(() => {});
        } else {
          this.$router.push({ path: '/special/list' });
        }
      },
      resetForm(formName) {
        this.$router.push({ path: '/special/list' });
      },
      deleteImg() {
        this.formData.imageUrl = '';
      },
      deleteImgEn() {
        this.formData.en_imageUrl = '';
      },
      doSelectedActives(selectedActives) {
        if (this.$route.query.type == 2) {
          const { groupIndex, activityIndex } = this.languagesActivity;
          const { webinar_id, subject, pv, webinar_state, start_time } = selectedActives;
          this.$set(this.language_group[groupIndex].webinar_list, activityIndex, {
            id: webinar_id,
            subject,
            pv,
            start_time,
            webinar_state
          });
          this.showActiveSelect = false;
        } else {
          this.formData.selectedActives = selectedActives;
          this.showActiveSelect = false;
        }

        // selectedActives.map(item => {
        //   this.formData.selectedActives.push(item);
        // })
        // let id = 'webinar_id';
        // this.formData.selectedActives = this.formData.selectedActives.reduce((all, next) => all.some((atom) => atom[id] == next[id]) ? all : [...all, next],[]);
        // this.showActiveSelect = false
      },
      // 删除事件
      deleteSpecial(id) {
        this.$vhConfirm('您确定要删除选中的直播吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          roundButton: true,
          lockScroll: false,

          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            this.formData.selectedActives.map((opt, index) => {
              if (opt.webinar_id == id) {
                this.formData.selectedActives.splice(index, 1);
              }
            });
            this.$vhall_paas_port({
              k: 100507,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: '',
                refer: '',
                s: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
          })
          .catch(() => {
            this.$vhMessage({
              message: `已取消删除`,
              showClose: true,
              // duration: 0,
              type: 'info',
              customClass: 'zdy-info-box'
            });
          });
      },
      // 跳转活动页面
      goWebinarSpecial(id, state, player) {
        this.$vhall_paas_port({
          k: 100508,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        if (player == 1) {
          // flash
          let href = `${
            process.env.VUE_APP_E_COMPANY_URL
          }/auth/check-token?after_login=mywebinar/edit?id=${id}&token=${sessionOrLocal.get(
            'SAAS_V3_SSO_TOKEN',
            'localStorage'
          )}`;
          window.open(href, '_blank');
        } else {
          let path = state === 4 ? '/live/vodEdit/' : '/live/edit/';
          const { href } = this.$router.resolve({ path: `${path}${id}` });
          window.open(href, '_blank');
        }
      },
      dragStart(e) {
        console.log('vhall saas Event 拖动开始::', e);
      },
      updateDrag(e) {
        this.$vhall_paas_port({
          k: 100506,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: '',
            subject_id: this.$route.params.id || '',
            refer: '',
            s: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
      },
      dragEnd(e) {
        console.log('vhall saas Event 拖动结束::', e);
      },
      sortChange(e) {
        console.log('拖拽事件', e);
      },
      addLanguageGroup() {
        this.language_group.push({ language_group_id: '', webinar_list: [{}, {}] });
      },
      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        if (!row.id) {
          if (columnIndex == 0) {
            return [1, 5];
          } else {
            return [0, 0];
          }
        }
      },
      addActivity(groupIndex, activityIndex) {
        this.multiple = false;
        this.showActiveSelect = true;
        this.languagesActivity = {
          groupIndex,
          activityIndex
        };
      },
      delWebinar(groupIndex, activityIndex) {
        this.$vhConfirm(
          '当前双语活动组若已应用到单场大会/系列会，关联的活动将同步删除您确定要删除选中的直播吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            roundButton: true,
            lockScroll: false,

            cancelButtonClass: 'zdy-confirm-cancel'
          }
        ).then(() => {
          this.$set(this.language_group[groupIndex].webinar_list, activityIndex, {});
        });
      },
      delGroup(groupIndex) {
        this.$vhConfirm(
          '当前双语活动组若已应用到单场大会/系列会，关联的活动将同步删除您确定要删除选中的活动组吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'zdy-message-box',
            roundButton: true,
            lockScroll: false,

            cancelButtonClass: 'zdy-confirm-cancel'
          }
        ).then(() => {
          this.language_group.splice(groupIndex, 1);
        });
      },
      checkGroupListInfo() {
        if (this.$route.query.type != 2) {
          return true;
        }
        if (this.language_group.length == 0) {
          return false;
        }
        for (let i = 0; i < this.language_group.length; i++) {
          for (let j = 0; j < this.language_group[i].webinar_list.length; j++) {
            if (!this.language_group[i].webinar_list[j].id) {
              return false;
            }
          }
        }
        return true;
      },
      tableRowClassName({ row, rowIndex }) {
        if (row.id && this.errorWebinar.includes(row.id.toString())) {
          return 'error_row';
        }
        return '';
      },
      checkGroup(group) {
        if (this.errorGroup.includes(String(group.language_group_id))) {
          return '该活动组已被删除';
        }
        for (let j = 0; j < group.webinar_list.length; j++) {
          if (this.errorWebinar.includes(String(group.webinar_list[j].id))) {
            return '该活动组中存在已删除活动';
          }
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .vh-form {
    background: #ffffff;
    padding: 48px 20px 1px 48px;

    .tox-tinymce {
      border-radius: 4px;
    }
  }

  .title_text {
    color: #999;
    font-size: 14px;
  }
  .switch__box {
    line-height: 35px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .vh-form-item {
    width: 100%;
    max-width: 668px;
    &:last-child {
      padding: 16px 0;
    }
  }
  .btnGroup {
    text-align: center;
    margin-top: 40px;
    .vh-button {
      width: 150px;
    }
  }
  /* 图片上传 */

</style>

<style lang="less" scoped>
  .languages_group {
    margin-bottom: 30px;
    line-height: 20px;
    .table_box {
      display: flex;
      .link {
        display: inline-block;
        max-width: 100%;
        cursor: pointer;
        color: #3562fa;
        vertical-align: middle;
      }
    }
    .error_group {
      font-size: 14px;
      color: #fb3a32;
      padding-left: 10px;
      line-height: 28px;
    }
    .group_handle {
      display: flex;
      align-items: center;
      padding: 0 20px;
      background: #fafafa;
      border-radius: 0 6px 6px 0;
    }
  }
  .statusTag {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
  }
  .tag_1 {
    background: #fb3a32;
  }
  .tag_2 {
    background: #3562fa;
  }
  .tag_3 {
    background: #999;
  }
  .tag_4 {
    background: #fa9a32;
  }
  .tag_5 {
    background: #14ba6a;
  }
  .vh-sort-tables {
    position: relative;
    width: 640px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;

    &__theader {
      height: 40px;
      line-height: 40px;
      background: #f7f7f7;
      color: #666666;
      & > div {
        display: inline-block;
      }
      &-id {
        width: 77px;
        padding-left: 24px;
        box-sizing: border-box;
      }
      &-title {
        width: 170px;
      }
      &-time {
        width: 120px;
      }
      &-status {
        width: 88px;
      }
      &-hots {
        width: 93px;
      }
      &-editor {
        width: 86px;
      }
    }

    &__tbody {
      &-tr {
        border-bottom: 1px solid #e6e6e6;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #1a1a1a;
        word-break: keep-all;
        &.tr_active {
          background: rgba(251, 58, 50, 0.1);
        }
        & > div {
          display: inline-block;
          vertical-align: top;
        }
        &:hover {
          background-color: #f5f5f5;
        }
      }
      &-selected {
        border: 1px solid #fb3a32;
      }

      &-id {
        width: 77px;
        padding-left: 24px;
        box-sizing: border-box;
      }
      &-title {
        width: 170px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        cursor: pointer;
        color: #3562fa;
        // &:hover{
        //   color: #FB3A32;
        // }
      }
      &-time {
        width: 130px;
      }
      &-status {
        width: 88px;
      }
      &-hots {
        width: 93px;
      }
      &-editor {
        width: 82px;
        i {
          font-size: 20px;
          cursor: pointer;
          &:hover {
            color: #3562fa;
          }
        }
      }

      .vh-sort-tables__tbody-editor {
        overflow: hidden;
        padding-top: 12px;
        & > .vh-button {
          float: left;
        }
      }
    }
  }
  .add_btn {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 12px;
    color: #8c8c8c;
  }
  /deep/ .vh-table .error_row {
    background: rgba(251, 58, 50, 0.1) !important;
  }
</style>
